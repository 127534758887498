import { Injectable, inject } from '@angular/core'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import {
  catchError,
  of,
  exhaustMap,
  tap,
  map,
  debounceTime,
  switchMap
} from 'rxjs'
import { invoicesActions } from './invoices.actions'
import { InvoicesService } from '../../infrastructure/invoices.service'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { InvoicesFacade } from './invoices.facade'
import { InvoicesAdapter } from '../../adapter/invoices/InvoicesAdapter'
import { AsyncOperations } from '../../domain/invoices/invoices-loading.model'
import { FromGetInvoiceDetailsResponse } from '../../adapter/invoices/FromGetInvoiceDetailsResponse'
import { FromGetDuplicateInvoicesResponse } from '../../adapter/invoices/FromGetDuplicateInvoicesResponse'
import { ToAddInvoiceRequest } from '../../adapter/invoices/ToAddInvoiceRequest'
import { invoiceDetailsActions } from './invoice-details/invoice-details.actions'
import { UpdateInvoiceOwnershipRequest } from '../../domain/invoices/update-invoice-ownership.request'
import { MS_BEFORE_RESET_LOADING_STATE } from './invoices.constants'
import { fromGetInvoicesRestResponse } from '../../adapter/invoices/FromGetInvoicesRestResponse'
import { CurrentUserFacade } from '@navix/current-user/domain'
import { platform_v2_1 } from '@navix/services-api-models'
import { toAddInvoiceFromOrder } from '../../adapter/invoices/ToAddInvoiceFromOrderRequest'

@Injectable()
export class InvoicesEffects {
  private actions$ = inject(Actions)
  private invoicesService = inject(InvoicesService)
  private alertsFacade = inject(LegacyAlertsFacade)
  private invoicesFacade = inject(InvoicesFacade)
  private currentUserFacade = inject(CurrentUserFacade)
  private service = inject(platform_v2_1.InvoiceCreationService)

  private invoiceAdapter = new InvoicesAdapter()

  constructor() {
    this.invoiceAdapter.set(FromGetInvoiceDetailsResponse)
    this.invoiceAdapter.set(FromGetDuplicateInvoicesResponse)
    this.invoiceAdapter.set(ToAddInvoiceRequest)
  }

  loadInvoices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.loadInvoices),
      switchMap(({ filters, strategy }) =>
        this.invoicesService.getInvoicesRest(filters).pipe(
          map(response => ({
            invoices: fromGetInvoicesRestResponse(response, filters)
          })),
          map(response => {
            switch (strategy) {
              case 'set':
                return invoicesActions.loadInvoicesSuccess(response)
              case 'upsert':
                return invoicesActions.upsertInvoicesSuccess(response)
              case 'upsertWithNavigation':
                return invoicesActions.upsertInvoicesWithNavigationSuccess(
                  response
                )
            }
          }),
          catchError(error => of(invoicesActions.loadInvoicesFail({ error })))
        )
      )
    )
  )

  loadInvoicesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoicesActions.loadInvoicesFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load invoices."
          })
        })
      ),
    { dispatch: false }
  )

  resetGetAllLoadingState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        invoicesActions.loadInvoicesSuccess,
        invoicesActions.loadInvoicesFail,
        invoicesActions.upsertInvoicesSuccess
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoicesActions.resetGetAllLoadingState())
    )
  )

  addInvoice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.addInvoice),
      tap(() => this.invoicesFacade.startLoading(AsyncOperations.addInvoice)),
      map(invoice => ({
        request: this.invoiceAdapter.convert(ToAddInvoiceRequest, invoice),
        tenantId: invoice.details.tenantId
      })),
      exhaustMap(({ request }) =>
        this.invoicesService.addInvoice(request).pipe(
          map(response =>
            invoicesActions.addInvoiceSuccess({
              invoiceId: response.vendorInvoiceId
            })
          ),
          catchError(httpError =>
            of(
              invoicesActions.addInvoiceFail({
                error: httpError.error
              })
            )
          ),
          tap(() => this.invoicesFacade.endLoading(AsyncOperations.addInvoice))
        )
      )
    )
  )

  addInvoiceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.addInvoiceSuccess),
      map(({ invoiceId }) =>
        invoiceDetailsActions.loadInvoiceDetails({
          invoiceId
        })
      )
    )
  )

  addInvoiceFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(invoicesActions.addInvoiceFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to create invoice"
          })
        })
      ),
    { dispatch: false }
  )

  setInvoiceStatusToReady$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.setInvoiceStatusToReady),
      exhaustMap(({ invoiceId }) =>
        this.invoicesService.setInvoiceStatusToReady(invoiceId).pipe(
          map(() => invoicesActions.setInvoiceStatusToReadySuccess()),
          catchError(httpError =>
            of(
              invoicesActions.setInvoiceStatusToReadyFail({
                error: httpError.error
              })
            )
          ),
          tap(() => this.invoicesFacade.endLoading(AsyncOperations.addInvoice))
        )
      )
    )
  )

  updateInvoiceOwnership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.updateInvoiceOwnership),
      map(({ invoiceId, invoiceUuid, ownInvoice }) => ({
        request: <UpdateInvoiceOwnershipRequest>{
          invoiceUuid,
          ownInvoice
        },
        invoiceId
      })),
      exhaustMap(invoice =>
        this.invoicesService.updateInvoiceOnwership(invoice.request).pipe(
          map(() =>
            invoicesActions.updateInvoiceOwnershipSuccess({
              invoiceId: invoice.invoiceId
            })
          ),
          catchError(httpError =>
            of(
              invoicesActions.updateInvoiceOwnershipFail({
                error: httpError.error
              })
            )
          ),
          tap(() =>
            this.invoicesFacade.endLoading(
              AsyncOperations.updateInvoiceOnwership
            )
          )
        )
      )
    )
  )

  updateInvoiceOwnershipSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.updateInvoiceOwnershipSuccess),
      map(({ invoiceId }) =>
        invoiceDetailsActions.loadInvoiceDetails({
          invoiceId
        })
      )
    )
  )

  addInvoiceFromOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invoicesActions.addInvoiceFromOrder),
      map(request =>
        toAddInvoiceFromOrder({
          invoiceForm: request.invoice,
          lineItems: request.lineItems
        })
      ),
      exhaustMap(invoice =>
        this.service.postApiV21InvoicesCreate({ requestBody: invoice }).pipe(
          map(() => invoicesActions.addInvoiceFromOrderSuccess()),
          catchError(httpError =>
            of(
              invoicesActions.updateInvoiceOwnershipFail({
                error: httpError.error
              })
            )
          )
        )
      )
    )
  )

  resetAddInvoiceFromOrder = createEffect((actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        invoicesActions.addInvoiceFromOrderSuccess,
        invoicesActions.addInvoiceFromOrderFail
      ),
      debounceTime(MS_BEFORE_RESET_LOADING_STATE),
      map(() => invoicesActions.resetAddInvoiceFromOrderLoadingState())
    )
  }, {})
}
