import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import { Action, createReducer, on } from '@ngrx/store'

import { Invoice } from '../../domain/invoices/invoice.model'
import {
  AsyncOperations,
  InvoicesLoadingState
} from '../../domain/invoices/invoices-loading.model'
import { invoicesActions } from './invoices.actions'

import {
  LoadingStatuses,
  getDefaultLoadingState,
  updateLoadingState
} from '@navix/shared/loading'
import {
  DataFilters,
  StatusFilter
} from '../../domain/invoices/data-filters.model'
import { DisputedReason } from '../../domain/invoices/disputed-reason.model'
import { DocumentType } from '../../domain/invoices/document-type.model'
import {
  DuplicateAction,
  DuplicateActions
} from '../../domain/invoices/duplicate-action.model'
import {
  EmailTemplate,
  EmailTemplateType
} from '../../domain/invoices/email-template-type.model'
import { FreightChargeTerm } from '../../domain/invoices/freight-charge-term.model'
import { ReferenceNumberType } from '../../domain/invoices/reference-number-type.model'
import { RelatedDocument } from '../../domain/invoices/related-document.model'
import { TerminateReason } from '../../domain/invoices/terminate-reason.model'
import { TypeOfException } from '../../domain/invoices/type-of-exception.model'
import { VendorInvoiceType } from '../../domain/invoices/vendor-invoice-type.model'
import { getStaticInvoiceStatusesQueue } from '../../infrastructure/static-data.service'
import { invoiceAuditHistoryReducers } from './invoice-audit-history/invoice-audit-history.reducer'
import { invoiceChargesReducers } from './invoice-charges/invoice-charges.reducer'
import { invoiceCurrencyReducers } from './invoice-currency/invoice-currency.reducer'
import { invoiceCustomerDetailsReducers } from './invoice-customer-details/invoice-customer-details.reducer'
import { invoiceDetailsReducers } from './invoice-details/invoice-details.reducer'
import { invoiceDocumentsActions } from './invoice-documents/invoice-documents.actions'
import { invoiceDocumentsReducers } from './invoice-documents/invoice-documents.reducer'
import { invoiceDuplicatesReducers } from './invoice-duplicates/invoice-duplicates.reducer'
import { invoiceExceptionsReducers } from './invoice-exceptions/invoice-exceptions.reducer'
import { invoiceLineItemsReducers } from './invoice-line-items/invoice-line-items.reducer'
import { invoiceNotesReducers } from './invoice-notes/invoice-notes.reducer'
import { invoiceReferenceNumbersReducers } from './invoice-reference-numbers/invoice-reference-numbers.reducer'
import { invoiceRelatedDocumentsReducers } from './invoice-related-documents/invoice-related-documents.reducer'
import { invoiceVendorDetailsReducers } from './invoice-vendor-details/invoice-vendor-details.reducer'
import { operationsReducers } from './operations/operations.reducer'
import { KnownInvoicesSortBy, defaultStatusFilters } from './static'
import { invoicesTypesReducers } from './types/types.reducer'
import { invoiceDisputesReducers } from './invoice-disputes/invoice-disputes.reducer'

export const INVOICES_FEATURE_KEY = 'feature-invoices'
export type FilterMemoryAvailableFilters =
  | Pick<
      DataFilters,
      | 'customers'
      | 'vendors'
      | 'typesOfExceptions'
      | 'statuses'
      | 'disputedReasons'
      | 'sources'
    >
  | Record<string, never>
export interface InvoicesState extends EntityState<Invoice> {
  selectedId?: number
  totalCount: number
  loading: InvoicesLoadingState
  filters: DataFilters
  filtersMemory: {
    [StatusFilter.audit]: FilterMemoryAvailableFilters
    [StatusFilter.auditMatched]: FilterMemoryAvailableFilters
    [StatusFilter.duplicates]: FilterMemoryAvailableFilters
    [StatusFilter.disputes]: FilterMemoryAvailableFilters
    [StatusFilter.approved]: FilterMemoryAvailableFilters
    [StatusFilter.all]: FilterMemoryAvailableFilters
    [StatusFilter.unmatched]: FilterMemoryAvailableFilters
    [StatusFilter.terminated]: FilterMemoryAvailableFilters
    [StatusFilter.disputesMatched]: FilterMemoryAvailableFilters
    [StatusFilter.disputesUnmatched]: FilterMemoryAvailableFilters
    [StatusFilter.readyToApprove]: FilterMemoryAvailableFilters
    [StatusFilter.disputeList]: FilterMemoryAvailableFilters
    [StatusFilter.ownedDisputeList]: FilterMemoryAvailableFilters
  }
  currentFiltersKey: {
    userUuid: string
    tenantUuid: string
  }
  statuses: { id: number; value: string; label: string }[]
  typesOfExceptions: TypeOfException[]
  disputedReasons: DisputedReason[]
  freightChargeTerms: FreightChargeTerm[]
  lineItemClasses: number[]
  referenceNumberTypes: ReferenceNumberType[]
  documentTypes: DocumentType[]
  emailTemplateTypes: EntityState<EmailTemplateType>
  terminateReasons: TerminateReason[]
  invoiceTypes: VendorInvoiceType[]
  duplicateActionTypes: DuplicateAction[]
  relatedDocuments: RelatedDocument[]
  standAloneWindowOpen: boolean

  //NEW EMAIL TEMPLATE TYPES
  emailTemplates: EmailTemplate[]
}

export interface InvoicesPartialState {
  readonly [INVOICES_FEATURE_KEY]: InvoicesState
}

export const invoicesAdapter: EntityAdapter<Invoice> =
  createEntityAdapter<Invoice>()

export const templatesAdapter = createEntityAdapter<EmailTemplateType>()

const dataFiltersInitialState: DataFilters = {
  search: undefined,
  page: 1,
  itemsPerPage: 25,
  sortBy: KnownInvoicesSortBy.CreatedDate,
  sortDirection: 'asc',
  status: StatusFilter.audit,
  customers: [],
  vendors: [],
  users: [],
  typesOfExceptions: [],
  statuses: [],
  disputedReasons: [],
  disputeReasonsV2: [],
  parties: [],
  sources: [],
  falseFlags: [],
  trueFlags: [],
  useUnownedUsers: false,
  hasNoExceptions: false,
  invoiceStatus: undefined,
  divisions: [],
  dateRangeTypes: [],
  from: undefined,
  to: undefined,
  remitTo: ''
}

export const initialInvoicesState: InvoicesState =
  invoicesAdapter.getInitialState({
    totalCount: 0,
    loading: getDefaultLoadingState(AsyncOperations),
    filters: { ...dataFiltersInitialState },
    currentFiltersKey: {
      userUuid: 'default',
      tenantUuid: 'default'
    },
    filtersMemory: {
      [StatusFilter.audit]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.audit]
      },
      [StatusFilter.auditMatched]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.auditMatched]
      },
      [StatusFilter.duplicates]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.duplicates]
      },
      [StatusFilter.disputes]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.disputes]
      },
      [StatusFilter.approved]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.approved]
      },
      [StatusFilter.all]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.all]
      },
      [StatusFilter.unmatched]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.unmatched]
      },
      [StatusFilter.terminated]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.terminated]
      },
      [StatusFilter.disputesMatched]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.disputesMatched]
      },
      [StatusFilter.disputesUnmatched]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.disputesUnmatched]
      },
      [StatusFilter.readyToApprove]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.readyToApprove]
      },
      [StatusFilter.disputeList]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.disputeList]
      },
      [StatusFilter.ownedDisputeList]: {
        ...dataFiltersInitialState,
        ...defaultStatusFilters[StatusFilter.ownedDisputeList]
      }
    },
    typesOfExceptions: [],
    disputedReasons: [],
    statuses: getStaticInvoiceStatusesQueue(),
    freightChargeTerms: [
      { description: 'Collect' },
      { description: 'Prepaid' },
      { description: 'Prepaid & Add' },
      { description: 'Third Party' }
    ],
    lineItemClasses: [
      // this is hardcoded from legacy version
      50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250,
      300, 400, 500
    ],
    referenceNumberTypes: [],
    documentTypes: [],
    emailTemplateTypes: templatesAdapter.getInitialState(),
    terminateReasons: [],
    invoiceTypes: [],
    duplicateActionTypes: [
      { description: 'Keep', id: DuplicateActions.Keep },
      { description: 'Terminate', id: DuplicateActions.Termiante }
    ],
    relatedDocuments: [],
    standAloneWindowOpen: false,
    emailTemplates: [],
    disputeReasons: []
  })

const reducer = createReducer(
  initialInvoicesState,
  on(
    invoicesActions.loadInvoices,
    (state): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getAll,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoicesActions.loadInvoicesSuccess,
    (state, { invoices }): InvoicesState =>
      invoicesAdapter.setAll(invoices, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.getAll,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    invoicesActions.loadInvoicesFail,
    (state): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getAll,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load invoices."
      })
    })
  ),
  on(
    invoicesActions.upsertInvoicesSuccess,
    (state, { invoices }): InvoicesState =>
      invoicesAdapter.upsertMany(invoices, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.getAll,
          status: LoadingStatuses.Completed
        })
      })
  ),
  on(
    invoicesActions.upsertInvoicesWithNavigationSuccess,
    (state, { invoices }): InvoicesState => {
      const setAllInvoices = invoicesAdapter.setAll(invoices, {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.getAll,
          status: LoadingStatuses.Completed
        })
      })

      return invoicesAdapter.upsertMany(
        state.ids
          .map(id => state.entities[id])
          .filter(entity => entity !== undefined) as Invoice[],
        {
          ...setAllInvoices,
          loading: updateLoadingState(state.loading, {
            operation: AsyncOperations.getAll,
            status: LoadingStatuses.Completed
          })
        }
      )
    }
  ),
  on(
    invoicesActions.resetGetAllLoadingState,
    (state): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getAll,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    invoicesActions.clearInvoices,
    (state): InvoicesState => invoicesAdapter.removeAll(state)
  ),
  on(
    invoicesActions.setTotalCount,
    (state, { count }): InvoicesState => ({
      ...state,
      totalCount: count
    })
  ),
  on(
    invoicesActions.setLoading,
    (state, { operation, loading, message }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation,
        status: loading,
        message: message ?? ''
      })
    })
  ),
  on(
    invoicesActions.resetLoading,
    (state, { operation }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation,
        status: LoadingStatuses.NotStarted,
        message: undefined
      })
    })
  ),
  on(
    invoicesActions.setListFilters,
    (state, { filters }): InvoicesState => ({
      ...state,
      filters
    })
  ),
  on(
    invoicesActions.patchListFilters,
    (state, { filters }): InvoicesState => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters
      }
    })
  ),
  on(
    invoicesActions.setListFiltersMemory,
    (state, { filters, status }): InvoicesState => ({
      ...state,
      filtersMemory: { ...state.filtersMemory, [status]: filters }
    })
  ),
  on(
    invoicesActions.setCurrentFiltersKey,
    (state, { userUuid, tenantUuid }) => ({
      ...state,
      currentFiltersKey: {
        userUuid,
        tenantUuid
      }
    })
  ),
  on(
    invoiceDocumentsActions.loadInvoiceDocumentsSuccess,
    (state, { documents, invoiceId }) =>
      invoicesAdapter.upsertOne(
        {
          id: invoiceId,
          documents
        } as Invoice,
        state
      )
  ),

  on(
    invoicesActions.updateInvoiceOwnership,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceOnwership,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoicesActions.updateInvoiceOwnership,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceOnwership,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(
    invoicesActions.updateInvoiceOwnershipFail,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceOnwership,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to update invoice ownership.'
      })
    })
  ),
  on(
    invoicesActions.setStandaloneWindowOpen,
    (state: InvoicesState, { standAloneWindowOpen }): InvoicesState => ({
      ...state,
      standAloneWindowOpen
    })
  ),
  on(invoicesActions.setSelectedId, (state, { selectedId }) => ({
    ...state,
    selectedId
  })),
  on(
    invoicesActions.reOrderInvoicesList,
    (state, { invoices }): InvoicesState =>
      invoicesAdapter.setAll(invoices, {
        ...state
      })
  ),

  ...invoiceDocumentsReducers,
  ...invoiceLineItemsReducers,
  ...invoiceChargesReducers,
  ...invoiceExceptionsReducers,
  ...invoiceReferenceNumbersReducers,
  ...invoicesTypesReducers,
  ...invoiceNotesReducers,
  ...invoiceAuditHistoryReducers,
  ...invoiceDocumentsReducers,
  ...invoiceVendorDetailsReducers,
  ...invoiceCustomerDetailsReducers,
  ...invoiceDetailsReducers,
  ...invoiceDuplicatesReducers,
  ...operationsReducers,
  ...invoiceCurrencyReducers,
  ...invoiceRelatedDocumentsReducers,
  ...invoiceDisputesReducers,
  on(
    invoicesActions.addInvoiceFromOrder,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceFromOrder,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoicesActions.addInvoiceFromOrderSuccess,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceFromOrder,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(
    invoicesActions.addInvoiceFromOrderFail,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceFromOrder,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to create invoice from order.'
      })
    })
  ),
  on(
    invoicesActions.resetAddInvoiceFromOrderLoadingState,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addInvoiceFromOrder,
        status: LoadingStatuses.NotStarted
      })
    })
  )
)

export function invoicesReducer(
  state: InvoicesState | undefined,
  action: Action
) {
  return reducer(state, action)
}
