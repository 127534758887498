import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Invoice } from '../../domain/invoices/invoice.model'
import { InvoiceForm } from '../../domain/invoices/invoice-form.model'
import { AsyncOperations } from '../../domain/invoices/invoices-loading.model'
import {
  DataFilters,
  StatusFilter
} from '../../domain/invoices/data-filters.model'
import { LoadingStatuses } from '@navix/shared/loading'

export const invoicesActions = createActionGroup({
  source: 'Invoices',
  events: {
    'load invoices': props<{
      filters: DataFilters
      strategy: 'set' | 'upsert' | 'upsertWithNavigation'
    }>(),
    'load invoices success': props<{ invoices: Invoice[] }>(),
    'upsert invoices success': props<{ invoices: Invoice[] }>(),
    upsertInvoicesWithNavigationSuccess: props<{ invoices: Invoice[] }>(),
    'load invoices fail': props<{ error: unknown }>(),
    resetGetAllLoadingState: emptyProps(),
    'clear invoices': emptyProps(),

    'set total count': props<{ count: number }>(),
    'set selected id': props<{ selectedId: number }>(),

    'init list filters': emptyProps(),
    'set list filters': props<{ filters: DataFilters }>(),
    'patch list filters': props<{ filters: Partial<DataFilters> }>(),
    'clear list filters': props<{ filters: Partial<DataFilters> }>(),
    setCurrentFiltersKey: props<{ userUuid: string; tenantUuid: string }>(),
    'init list filters memory': emptyProps(),
    'set list filters memory': props<{
      filters: Partial<DataFilters>
      status: StatusFilter
    }>(),

    'set standalone window open': props<{ standAloneWindowOpen: boolean }>(),
    'init standalone window open': emptyProps(),

    'set loading': props<{
      operation: AsyncOperations
      loading: LoadingStatuses
      message?: string
    }>(),

    'reset loading': props<{
      operation: AsyncOperations
    }>(),

    'add invoice': props<Pick<InvoiceForm, 'details'>>(),
    'add invoice success': props<{ invoiceId: number }>(),
    'add invoice fail': props<{ error: unknown }>(),

    'set invoice status to ready': props<{ invoiceId: number }>(),
    'set invoice status to ready success': emptyProps(),
    'set invoice status to ready fail': props<{ error: unknown }>(),

    'update invoice ownership': props<{
      invoiceId: number
      invoiceUuid: string
      ownInvoice: boolean
    }>(),
    'update invoice ownership success': props<{ invoiceId: number }>(),
    'update invoice ownership fail': props<{ error: unknown }>(),
    reOrderInvoicesList: props<{ invoices: Invoice[] }>(),
    addInvoiceFromOrder: props<{
      invoice: Pick<InvoiceForm, 'details' | 'stops' | 'defaultCharges'>
      lineItems: Invoice['lineItems']
    }>(),
    addInvoiceFromOrderSuccess: emptyProps(),
    addInvoiceFromOrderFail: props<{ error: unknown }>(),
    resetAddInvoiceFromOrderLoadingState: emptyProps()
  }
})
