import { on } from '@ngrx/store'
import { InvoicesState, invoicesAdapter } from '../invoices.reducer'
import { invoiceDetailsActions } from './invoice-details.actions'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../../domain/invoices/invoices-loading.model'
import { Invoice } from '../../../domain/invoices/invoice.model'

export const invoiceDetailsReducers = [
  on(
    invoiceDetailsActions.loadInvoiceDetails,
    (
      state: InvoicesState,
      { invoiceId, ignoreNewSelectedId }
    ): InvoicesState => ({
      ...state,
      selectedId: ignoreNewSelectedId ? state.selectedId : invoiceId,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDetails,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDetailsActions.loadInvoiceDetailsSuccess,
    (state: InvoicesState, { invoice }): InvoicesState => ({
      ...invoicesAdapter.upsertOne(invoice, state),
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDetails,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(
    invoiceDetailsActions.loadInvoiceDetailsFail,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDetails,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load invoice details."
      })
    })
  ),
  on(
    invoiceDetailsActions.resetLoadInvoiceDetailsLoadingState,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getDetails,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(
    invoiceDetailsActions.loadInvoiceTenantId,
    (state: InvoicesState, { invoiceId }): InvoicesState => ({
      ...state,
      selectedId: invoiceId,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getTenantId,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDetailsActions.loadInvoiceTenantIdSuccess,
    (state: InvoicesState, { invoiceId, tenant }): InvoicesState => ({
      ...invoicesAdapter.upsertOne({ id: invoiceId, tenant } as Invoice, state),
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getTenantId,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(
    invoiceDetailsActions.loadInvoiceTenantIdFail,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.getTenantId,
        status: LoadingStatuses.Failed,
        message: "There's an error trying to load invoice tenant id."
      })
    })
  ),
  on(
    invoiceDetailsActions.updateInvoiceOtherDetails,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceOtherDetails,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDetailsActions.addTags,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceOtherDetails,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDetailsActions.removeTags,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceOtherDetails,
        status: LoadingStatuses.InProgress
      })
    })
  ),
  on(
    invoiceDetailsActions.updateInvoiceOtherDetailsSuccess,
    (state: InvoicesState, { invoiceId }): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceOtherDetails,
        status: LoadingStatuses.Completed,
        message: 'Invoice was successfully updated.',
        metadata: invoiceId
      })
    })
  ),
  on(
    invoiceDetailsActions.updateInvoiceOtherDetailsFail,
    (state: InvoicesState, httpError): InvoicesState => {
      const message = String(httpError.error.error.message)

      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.updateInvoiceOtherDetails,
          status: LoadingStatuses.Failed,
          message:
            message ??
            `There's an error trying to update invoice other details.`
        })
      }
    }
  ),
  on(
    invoiceDetailsActions.resetUpdateInvoiceOtherDetailsLoadingState,
    (state: InvoicesState): InvoicesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateInvoiceOtherDetails,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
